.navbar {
      background-image: linear-gradient(to bottom right, #d67b19ca, #df8816ca, #e89611ca, #f0a40cca, #f7b306ca, #f0b418ca, #e9b523ca, #e3b62dca, #ceaa3cca, #bb9d47ca, #a79150ca, #948458ca), url("/images/Subtle-Grunge-Texture-9-Seamless.jpg"), ;
}

.nav-item {


      & .nav-link {
            color: var(--data-color-dark);
            transition: all 300ms;

            &.selected {
                  text-decoration: underline;
                  text-shadow: 3px 3px 3px white;
            }

            &:hover {
                  color: var(--data-color-accent);
            }

      }



      font-size: 1.75em;
}

.lang-nav {
      cursor: pointer;
}

html {
      scroll-behavior: smooth;
}