.discussion-container {
  display: flex;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  margin: 0;
  padding: 0;

  &>* {
    margin: 0;
  }

  & .discussion-nav {
    flex: 0 1 18%;
    padding: 0.75em;
    background-color: var(--data-color-main);
    box-shadow: 3px 0px 10px 5px rgba(0, 0, 0, 0.262);

    & .logo {
      width: 100%;
      height: auto;
    }

    & .nav {
      margin: 0;
      padding: 1em 0;

      li,
      a {
        color: var(--data-color-dark);
      }
    }

    & .selected {
      text-decoration: underline;
      text-shadow: 3px 3px 3px white;
    }
  }

  & .discussion-dashboard {
    flex: 1 1 auto;
    height: 100%;
    min-height: 100vh;
    padding: 4em;

    & .admin-row {
      margin: 10px 0;
      padding: 5px;
      border-radius: 15px;
      cursor: pointer;
      transition: all 300ms;
      border-bottom: 2px solid var(--data-color-main);

      &:hover {
        background-color: var(--data-color-main);
        color: white;
      }
    }
  }

  & .discussion-main {
    flex: 0 1 65%;
    height: 100%;
    min-height: 100vh;
    padding: 4em;

    & * {
      white-space: pre-line;
    }

    & .important {
      font-weight: bolder;
      font-style: italic;
      font-size: 0.8em;
    }

    & .form-summary {
      & p {
        white-space: pre-line;
      }

      border: 2px solid var(--data-color-main);
      border-radius: 5px;
      padding: 1em;
      background-color: white;
      height: 50%;
      max-height: 500px;
      overflow-y: scroll;
      box-shadow: inset 5px 5px 5px 1px rgba(0, 0, 0, 0.249);

      &>* {
        white-space: pre-line;
      }

      & .answer {
        border-bottom: 1px solid var(--data-color-accent);
        padding: 1em;
      }
    }

    & .messagebox {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 1em;

      & .message-text {
        flex: 1 1 auto;
        margin: 0 1em;
      }

      & .message-btn {
        flex: 0 1 20%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        & button {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.276);
          border: 2px solid transparent;
          border-radius: 10px;
          background-color: var(--data-color-main);
          cursor: pointer;
          transition: all 250ms;

          &:hover {
            border: 2px solid var(--data-color-secondary);
            background-color: var(--data-color-main);
          }

          &:active {
            box-shadow: none;
            border: 2px solid white;
          }

          &.is-invalid {
            background-color: var(--text-color-inactive);
            font-style: italic;
            pointer-events: none;
          }
        }
      }
    }

    & .messages-container {
      display: flex;
      flex-direction: column;

      & .message {
        height: 100%;
        width: 80%;
        padding: 0.3em 0;
        margin-bottom: 25px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & .message-text {
          flex: 1 1 auto;
          border-radius: 30px;
          padding: 1.25em;
          white-space: pre-line;
          cursor: pointer;
        }

        & .delete-message-button {
          color: var(--text-color-invalid);
          cursor: pointer;
          margin-left: 10px;
          vertical-align: baseline;
          font-size: 1.4em;
        }

        & .message-date {
          font-size: 0.9em;
          flex: 0 0 100%;
          color: darken(grey, 30%);
        }

        &.participant {
          align-self: flex-start;

          &.new {
            & .message-text {
              border-left: 10px solid var(--data-color-secondary);
              border-right: 10px solid var(--data-color-secondary);
            }

            & .message-date {
              color: var(--data-color-main);
              font-style: italic;
            }
          }

          & .message-text {
            background-color: var(--data-color-main);
          }

          & .message-date {
            text-align: left;
          }
        }

        &.admin {
          align-self: flex-end;

          &.new {
            & .message-text {
              border-left: 10px solid var(--data-color-secondary);
              border-right: 10px solid var(--data-color-secondary);
            }

            & .message-date {
              color: var(--data-color-main);
              font-style: italic;
            }
          }

          & .message-text {
            background-color: var(--data-color-accent);
          }

          & .message-date {
            text-align: right;
          }

          &.admin-only .message-text {
            background-color: var(--data-color-accent);
            opacity: 0.7;
          }
        }

        &.deleted{
          margin-bottom: 5px;
          .message-date {
           color:var( --text-color);
           font-size: 0.7em;
           padding: 0;
           p{padding: 0;margin: 0;}
          }
          .message-text{
            background-color: transparent!important;
            padding: 0;
            color:var( --text-color);
            border-radius: 5px;
            text-align: right;
          }
        }
      }
    }
  }

  & .discussion-aside {
    flex: 0 0 20%;
    padding: 4em 2em;

    & .file-box {
      background-color: white;
      border-radius: 10px;
      border: 1px solid var(--data-color-main);
      padding: 1em;

      & .file {
        text-decoration: underline;
      }
    }

    & .attachment {
      & .link {
        cursor: pointer;
      }
    }
  }

  & .conditions {
    white-space: pre-line;
    border-left: 1px solid var(--data-color-main);
    border-right: 1px solid var(--data-color-main);
    font-size: 1.25em;
    font-style: italic;
  }

  & .correction.correction-necessary {
    color: $alert;
  }

  & .decalage {
    margin-left: 60px;
  }

  & .is-new {
    color: var(--data-color-secondary);
  }

  & .is-old {
    color: $invalid;
  }
}