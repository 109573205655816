@font-face {
      font-family: rakesly;
      src: url("/fonts/rakesly_rg.ttf");
}

@font-face {
      font-family: bellGothic;
      src: url("/fonts/BellGothicStd-Black.otf");
}

body{
      color:var(--data-color-dark);
}

h1 {
      font-family: rakesly, sans-serif;
      text-transform: uppercase;
}

h2,
h3 {
      font-family: bellGothic, sans-serif;

}

h4,
h5,
h6,
p {
      font-family: Arial, Helvetica, sans-serif;
}

a,
button {
      font-size: 1.3em;
      font-family: rakesly, sans-serif;

}

button h4,
button h5,
button h6 {
      font-family: rakesly, sans-serif;
}

.button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      background-color: var(--data-color-main);
      border-radius: 5px;
      margin: 5px;
      cursor: pointer;
      color: var(--data-color-light);
      font-size: 1.25em;
      text-transform: uppercase;
      font-weight: 600;
      opacity: 1;

      &:hover {
            opacity: 0.8;
      }

      &.cancel-button {
            background-color: var(--data-color-light);
            color: var(--data-color-main);
            border: 2px solid var(--data-color-main);
      }
      &.disabled{
            pointer-events: none;
            background-color: var(--text-color-inactive);
      }
}