.messageBox {
      position: absolute;
      z-index: 999;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      width: 100vw;
      border: 1px solid var(--main-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .box {
            position: relative;
            width: 30%;
            min-height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
      }
}

.spinner-container {
      /* Set up proportionate scaling */
      position: fixed;
      display: block;

      width: 100vw;
      height: 100vh;
      background-size: cover;
      z-index: 999;

      /* Set up positioning */

      top: 0;
      left: 0;

      background-color: rgb(225, 135, 0);
}