@keyframes fadein-anim {
      from {
            opacity: 0;
      }

      to {
            opacity: 100%;
      }
}

@keyframes fadeout-anim {
      from {
            opacity: 1;
      }

      to {
            opacity: 100%;
      }
}

@keyframes fadeinout {
      0% {
            opacity: 0;
      }

      10% {
            opacity: 1;
      }

      90% {
            opacity: 1;
      }

      100% {
            opacity: 0;
      }
}

.fade-in-out {
      animation: fadeinout 2500ms ease-in-out forwards;
}