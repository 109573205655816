.edit-translations {
    .translations-row {
        width: 70vw;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        input {
            flex: 1 0 90%;

            &.modified {
                border: 4px solid var(--data-color-main);
                color: var(--data-color-main);
                font-style: italic;

                & + .cancel-translation-btn {
                    display: initial;
                }
            }
        }
    }

    .save-translations-btn {
        display: block;
        margin-bottom: 15px;
        margin-left: auto;

        &:disabled {
            pointer-events: none;
            background-color: var(--text-color);
            color: var(--text-color-disabled);
            box-shadow: none;
        }
    }

    .cancel-translation-btn {
        display: none;
        cursor: pointer;
        font-size: 2em;
        color: var(--text-color-invalid);
        margin-left: 3px;

        i {
            color: var(--text-color-invalid);
        }
    }
}