.pointer {
  cursor: pointer;
}

.btn-main {
  background-color: var(--data-color-main);

  border-radius: 10px;
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.482);
  transition: all 450ms;

  &,
  & * {
    transition: all 450ms;
  }

  &:hover {
    box-shadow: 0px 10px 8px 10px rgba(0, 0, 0, 0.269);
    transform: translate(-10px, -10px);
    border: 4px solid var(--data-color-secondary);
  }
}

.btn-alert {
  background-color: $alert;
  color: white;

  border-radius: 10px;
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.482);
  transition: all 450ms;

  &,
  & * {
    transition: all 450ms;
  }

  &:hover {
    box-shadow: 0px 10px 8px 10px rgba(0, 0, 0, 0.269);
    transform: translate(-10px, -10px);
    border: 4px solid white;
  }
}

.btn-primary {
  border-radius: 10px;
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.482);
  transition: all 450ms;

  &,
  & * {
    transition: all 449ms;
  }

  &:hover {
    box-shadow: 0px 10px 8px 10px rgba(0, 0, 0, 0.269);
    transform: translate(-10px, -10px);
    border: 4px solid var(--data-color-secondary);
  }
}

.btn-accent {
  background-color: var(--data-color-accent);
  border-radius: 10px;
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.482);
  transition: all 450ms;

  &,
  & * {
    transition: all 450ms;
  }

  &:hover {
    box-shadow: 0px 10px 8px 10px rgba(0, 0, 0, 0.269);
    transform: translate(-10px, -10px);
    border: 4px solid var(--data-color-secondary);
  }
}

.btn-secondary {
  background-color: var(--data-color-secondary);
  border-radius: 10px;
  box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.482);
  transition: all 450ms;

  &,
  & * {
    transition: all 450ms;
  }

  &:hover {
    box-shadow: 0px 10px 8px 10px rgba(0, 0, 0, 0.269);
    transform: translate(-10px, -10px);
    border: 4px solid var(--data-color-secondary);
  }
}

.decobloc-container {
  width: 80%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;

  & .decobloc {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    bottom: 0;
    background-image: url('/images/main-robot_plein.png'),
      url('/images/tachesVertes.png');
    background-size: 25%, auto;
    background-position: -10% 90%, 0 80%;
    background-repeat: no-repeat;
    pointer-events: none;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: stretch;

  .btn-group {
    background-color: white;
    border: 2px solid var(--data-color-main);
    border-radius: 10px;
    box-shadow: 0px 3px 5px 5px rgba(0, 0, 0, 0.482);
    transition: all 450ms;

    &,
    & * {
      transition: all 450ms;
      color: var(--data-color-main);
    }

    &:hover {
      box-shadow: 0px 10px 8px 10px rgba(0, 0, 0, 0.269);
      transform: translate(-10px, -10px);
      border: 4px solid var(--data-color-secondary);

      & * {
        color: var(--data-color-secondary);
      }
    }
  }
}
