.discussion-container {
    .edit-home{
        .edit-home-bg img{
            max-height: 300px;
            max-width: 300px;
            object-fit: contain;
        }
        .save-button{
            margin-top:35px;
        }
    }
}