.form-container {
      display: flex;
      height: 100%;
      width: 100%;

      & .form-nav {
            flex-basis: 14%;
            flex-shrink: 0;
            border-right: 3px solid var(--data-color-main);
            padding: 1em;
      }

      & .form-buttons {
            display: flex;
            justify-content: flex-end;

            align-items: center;

            &>* {
                  margin: 0 1em;
            }
      }
}

.question {
      padding: 1em 0;
      border-bottom: 1px solid var(--data-color-main);
}

.section-text {
      white-space: pre-line;
      margin-bottom: 0.4em;
}

.no_correction_needed {
      color: grey;
}

.popup-container {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 666;
      width: 100%;
      height: 100%;

      .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: black;
            opacity: 0.5;
            z-index: 100;
      }

      .content {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 50px auto;
            padding: 50px;
            z-index: 999;
            background: white;
            border: 2px solid orange;
            width: 70%;
            text-align: center;

            .button-group {
                  display: flex;
                  justify-content: space-around;
                  margin-top: 15px;
            }
      }

      &.invisible {
            display: none;
      }
}