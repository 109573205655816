
.homePage {
      height: 100%;
      min-height: 100vh;
      width: 100vw;
      max-width: 100vw;
      overflow-x: hidden;
      background-image: url("/images/bg.jpg"),linear-gradient(to bottom right,var(--data-color-main), var(--data-color-main)) ;
      background-blend-mode:soft-light;
            background-position: 0 100%, 0 0 ;

      .description {
            color: var(--data-color-main);
      }

      .container {
            height: 100%;
            min-height: 100vh;
            //background-image: linear-gradient(to right, #03297f00, #00277677, #00246c99, #00226399, #001f5a99, #041e5499, #071d4e99, #0a1c4899, #101d4399, #141d3e99, #171e3977, #1a1e3400);
      }



      .logo-container {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 100%;

            .logo {
                  max-height: 200px;
                  min-height: 150px;
            }


      }

      .newUserBloc {
            color: var(--data-color-light);
            letter-spacing: 0.2em;

            &:hover {
                  color: white;
            }
      }
}