/* VARIABLES */
$main: #ff9900;
$secondary: #c8ff00;
$accent: #64dbff;
$dark: #343434;
$light:white;
$alert:#b31b00;
$invalid: var(--text-color-inactive);

:root {
      --data-color-main: #{$main};
      --data-color-secondary: #{$secondary};
      --data-color-accent: #{$accent};
      --data-color-dark: #{$dark};
      --data-color-light: #{$light};
      --text-color: #777;
      --text-color-light: #ccc;
      --text-color-disabled: rgb(228, 228, 228);
      --text-color-alert: rgb(96, 37, 37);
      --text-color-valid: rgb(103, 155, 58);
      --text-color-invalid: rgb(150, 12, 12);
      --text-color-inactive: rgb(126, 126, 126);
      --border-color: #eee;
      --bg-color: #f9f9f9;
      --neutral-color: #fff;
      --alert-color: rgb(111, 69, 69);
      --calendar-disabled-color: rgb(244, 244, 244);
      --calendar-out-of-range-color: rgb(155, 168, 184);
      --calendar-blocked-color: rgb(37, 61, 96);
}

@mixin background-tint($tint_color, $image_url) {
      background-image: linear-gradient($tint_color, $tint_color), url($image_url);
}

h1 {
      color: var(--data-color-main);
}

.btn {
      & a {

            &,
            &:visited,
            &:active {
                  color: var(--neutral-color);
            }
      }

      &.is-invalid {
            background-color: var(--text-color-inactive);
            font-style: italic;
            pointer-events: none;
      }
}

li,
a {
      color: var(--data-color-main);
}

.main-color {
      color: var(--data-color-main);
}

.secondary-color {
      color: var(--data-color-secondary);
}

.accent-color {
      color: var(--data-color-accent);
}