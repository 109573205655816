.client-dashboard {
      & .confirmed {
            color: var(--text-color-valid);
      }

      & .unconfirmed {
            color: var(--text-color-invalid);
      }

      & .canceled {
            color: var(--text-color-inactive);
            text-decoration: line-through;
      }
}

.client-register {
      height: 100%;
      width: 100vw;
      background-image: linear-gradient(to right bottom, #03297fdd, #002776dd, #00246cdd, #002263dd, #001f5add, #041e54dd, #071d4edd, #0a1c48dd, #101d43dd, #141d3edd, #171e39dd, #1a1e34dd), url("/images/home.jpg");
      background-size: cover, cover;
      background-position: center, center, center;

      & .container {
            height: 100%;
            min-height: 100vh;
            background-image: linear-gradient(to right, #03297f00, #00277677, #00246c99, #00226399, #001f5a99, #041e5499, #071d4e99, #0a1c4899, #101d4399, #141d3e99, #171e3977, #1a1e3400);
      }
}